import { render, staticRenderFns } from "./InvitationCustom.vue?vue&type=template&id=c6557092&scoped=true"
import script from "./InvitationCustom.vue?vue&type=script&lang=js"
export * from "./InvitationCustom.vue?vue&type=script&lang=js"
import style0 from "../components/index.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./InvitationCustom.vue?vue&type=style&index=1&id=c6557092&prod&lang=css"
import style2 from "./InvitationCustom.vue?vue&type=style&index=2&id=c6557092&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6557092",
  null
  
)

export default component.exports